import Cookies from 'js-cookie';
import { useSearchParams } from 'react-router-dom';

import { snakeCaseToCamel } from 'app/shared/utils/string';

const forceVariantValueIsValid = (value: string) => {
  return value === 'a' || value === 'b' || value === 'clear';
};

// Returns an object with key value pairs for valid force_variant params and their values
// Example: if you go to /cities/nyc?force_variant=flag_test_v2_a,some_test_b and we're fetching
// feature flags named flag_test_v2 and some_test, this function will return:
//   {
//     flag_test_v2: 'a',
//     some_test: 'b'
//   }
// Then each of these key pair values will be stored in a cookie to be later used to force this user
// into a specific variant of a test
//
// If you pass 'clear' as a value for a particular feature flag, then instead of storing that value
// in a cookie, the EXISTING COOKIE FOR THAT FEATURE FLAG WILL BE DELETED (in another function)
// Example: if you go to /cities/nyc?force_variant=flag_test_v2_a,some_test_clear,
// this function will return:
//   {
//     flag_test_v2: 'a',
//     some_test: 'clear'
//   }
// which will cause the cookie for the some_test flag to be deleted

const getForceVariantValuesObject = (
  forceVariantParam: string | null,
  flags: string[]
) => {
  if (!forceVariantParam) {
    return {};
  }

  const variantValuesObject = {};
  // Get all forceVariant values into an array
  const variantValues = forceVariantParam.split(',');

  // flag is in snake_case e.g. some_feature_flag
  for (const flag of flags) {
    // Check if flag is present within forceVariant param
    if (forceVariantParam.includes(flag)) {
      // Grab the flag's param value and store it on variantValuesObject
      const validForceVariantString = variantValues.filter((value: string) =>
        value.includes(flag)
      )[0];
      const forceVariantValue = validForceVariantString.replace(`${flag}_`, '');

      if (forceVariantValueIsValid(forceVariantValue)) {
        variantValuesObject[flag] = forceVariantValue;
      }
    }
  }

  return variantValuesObject;
};

// Takes all valid force variant params and stores them on cookies, OR deletes cookies if requested
const setForceVariantCookieValues = (
  forceVariantParam: string | null,
  flags: string[]
) => {
  // This is an object with key value pairs for each valid test force param present in the url
  const forceVariantValuesObject = getForceVariantValuesObject(
    forceVariantParam,
    flags
  );

  // We grab the object keys to know if the object is empty
  const forceVariantValuesObjectKeys = Object.keys(forceVariantValuesObject);

  // If object is not empty we store each key value pair in a cookie OR delete the existing cookie
  if (forceVariantValuesObjectKeys.length > 0) {
    for (const forceVariantValueKey of forceVariantValuesObjectKeys) {
      if (forceVariantValuesObject[forceVariantValueKey] === 'clear') {
        Cookies.remove(forceVariantValueKey);
      } else {
        Cookies.set(
          forceVariantValueKey,
          forceVariantValuesObject[forceVariantValueKey]
        );
      }
    }
  }
};

const getForceVariantValue = (value?: string) => {
  if (value === 'a') {
    return false;
  } else if (value === 'b') {
    return true;
  } else {
    return undefined;
  }
};

const getForceVariantCookieValues = (flags: string[]) => {
  const forceVariantCookieValuesObject = {};

  // flag is in snake_case e.g. some_feature_flag
  for (const flag of flags) {
    const forceVariantValue = getForceVariantValue(Cookies.get(flag));
    if (forceVariantValue !== undefined) {
      forceVariantCookieValuesObject[
        snakeCaseToCamel(flag)
      ] = forceVariantValue;
    }
  }

  return forceVariantCookieValuesObject;
};

// This function:
// - Sets a cookie indicating which variant(s), if any, the user is forcing via a param in the url,
//   IF the user passed a param in the url
// - Gets the cookie set above, whether or not the user passed a param in the url (so that the
//   'force variant' functionality follows the user around the website even though they only passed
//   the param in the url ONCE)
// - Returns an object with feature flag names in camelCase and true/false values indicating whether
//   or not each feature flag should be 'forced' to be true, e.g.:
//     {
//       someFeatureFlag: true,
//       someOtherFeatureFlag: false
//     }
//
// For how the force_variant url param works, see comment below
const useForceVariants = (flags?: string[]) => {
  const [searchParams] = useSearchParams();

  if (!flags || flags.length == 0) {
    return {};
  }

  // To force a specific feature flag variant(s) users need to include
  // '?force_variant=feature_flag_name_variantvalue' in the url
  //
  // Examples:
  //   - /cities/nyc?force_variant=city_page_v2_a will force variant a for the city_page_v2 feature
  //     flag, which is the OLD city page
  //   - /cities/nyc?force_variant=city_page_v2_b will force variant b for the city_page_v2 feature
  //     flag, which is the NEW city page
  //   - /cities/nyc?force_variant=city_page_v2_clear will DELETE the cookie storing whatever variant
  //     is currently being forced for the city_page_v2 feature, thus reverting the behavior to whatever
  //     would happen if no variant at all were being forced either way
  //
  // There can be more than one feature flag + variant value present in the url - if multiple, there
  // needs to be a comma between the values - example: force_variant=city_page_v2_a,event_genres_b
  const forceVariantParam = searchParams.get('force_variant');

  if (forceVariantParam) {
    setForceVariantCookieValues(forceVariantParam, flags);
  }

  return getForceVariantCookieValues(flags);
};

export default useForceVariants;
