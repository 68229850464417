import { css } from 'styled-components';

import { CommonTheme } from 'app/shared/theme';

export interface WithFlexProps {
  flex?: number | string;
  flexDirection?: string;
  flexWrap?: string;
  flexFlow?: string;
  justifyContent?: string;
  alignItems?: string;
  alignContent?: string;
  gap?: number;
  rowGap?: number;
  columnGap?: number;
  marginInline?: string;
}

interface WithFlexCSSProps extends WithFlexProps {
  theme: CommonTheme;
}

export const withFlexCSS = ({
  flex,
  flexDirection,
  flexWrap,
  flexFlow,
  justifyContent,
  alignItems,
  alignContent,
  gap,
  rowGap,
  columnGap,
}: WithFlexCSSProps) => css`
  ${flex && `flex: ${flex};`}

  ${(flexDirection ||
    flexWrap ||
    flexFlow ||
    justifyContent ||
    alignItems ||
    alignContent ||
    gap) &&
    css`
      display: flex;

      ${flexDirection && `flex-direction: ${flexDirection};`}
      ${flexWrap && `flex-wrap: ${flexWrap};`}
      ${flexFlow && `flex-flow: ${flexFlow};`}
      ${justifyContent && `justify-content: ${justifyContent};`}
      ${alignItems && `align-items: ${alignItems};`}
      ${alignContent && `align-content: ${alignContent};`}


      ${({ theme }) => css`
        ${gap &&
          css`
            gap: ${theme.ruler[gap]}px;
          `}}
        ${rowGap &&
          css`
            row-gap: ${theme.ruler[rowGap]}px;
          `}}
        ${columnGap &&
          css`
            column-gap: ${theme.ruler[columnGap]}px;
          `}}
      `}
    `}
`;
