export const upperFirst = (str?: string) => {
  if (!str) {
    return '';
  }
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const upperFirstLeaveRestUnchanged = (str?: string) => {
  if (!str) {
    return '';
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const matchExternalUrl = (url: string) =>
  url.match(/^(http|https):\/\/(.*)/g) !== null;

export const transformImg = (url: string, transform: string) => {
  if (!url || (!url.includes('prismic.io') && !url.includes('imgix.net'))) {
    return url;
  }

  const concatenator = url.indexOf('?') > -1 ? '&' : '?';
  return `${url}${concatenator}${transform}`;
};

export const truncateByWord = (
  text: string | null,
  length: number,
  append: string
) => {
  if (text === null) {
    return '';
  }

  const trimmable =
    '\u0009\u000A\u000B\u000C\u000D\u0020\u00A0\u1680\u180E\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200A\u202F\u205F\u2028\u2029\u3000\uFEFF';
  const reg = new RegExp('(?=[' + trimmable + '])');
  const words = text.split(reg);
  let count = 0;
  const truncated_text = words
    .filter(function(word) {
      count += word.length;
      return count <= length;
    })
    .join('');

  if (truncated_text.length < text.length) {
    return truncated_text + append;
  }

  return truncated_text;
};

export const truncateByChar = (
  text: string | null,
  length: number,
  append?: string
) => {
  if (text === null) {
    return '';
  }

  append = append || '...';

  if (text.length > length) {
    text = text.substring(0, length) + append;
  }

  return text;
};

export const preserveLineBreaks = (text: string) =>
  text.replace(/\n/g, '<br />');

export const capitalize = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export const snakeCaseToDash = (str: string) =>
  str
    .split('')
    .map((ch: string) => (ch === '_' ? '-' : ch))
    .join('');

export const snakeCaseToCamel = (str: string) =>
  str.toLowerCase().replace(/([-_][a-z])/g, (group: string) =>
    group
      .toUpperCase()
      .replace('-', '')
      .replace('_', '')
  );

export const convertToQaId = (value: string) =>
  value
    .toLowerCase()
    .split(' ')
    .join('');

export const buildFullName = (
  firstName: string | undefined | null,
  lastName: string | undefined | null
) => {
  if (firstName === 'null') {
    firstName = null;
  }
  if (lastName === 'null') {
    lastName = null;
  }
  return ((firstName || '') + ' ' + (lastName || '')).trim();
};

export const snakecaseToTitleCase = (string: string | undefined) => {
  if (!string) {
    return;
  }

  let sentence = string.toLowerCase().split('_');
  for (let i = 0; i < sentence.length; i++) {
    sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
  }
  return sentence;
};

export const commaSeparatedList = (strs?: string[]) =>
  strs
    ? strs.reduce(
        (a, b, i, array) =>
          a +
          (i < array.length - 1
            ? ', '
            : array.length > 2
            ? ', and '
            : ' and ') +
          b
      )
    : '';

export const removeDuplicateAndEmptyStrings = (strings: string[]) =>
  strings
    .filter((p: string) => p !== '')
    .filter(
      (p: string, index: number, self: any) =>
        self.findIndex((p2: any) => p2 === p) === index
    );
