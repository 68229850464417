import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { AuthContext } from 'app/shared/context/Auth';
import { PermissionsContext } from 'app/shared/context/Permissions';
import ExternalRedirect from 'app/shared/components/atoms/ExternalRedirect';

const userHasCommercialCuratorGroup = (permissions?: any[]) =>
  permissions &&
  permissions
    .filter((p: any) => p.resourceType === 'CuratorGroup')
    .some(
      (p: any) =>
        p.resourceExtraData &&
        p.resourceExtraData.curatorGroupType == 'commercial'
    );

const withUserAgreements = (
  PassedComponent: React.ComponentType<any>,
  permissionName?: string
) => {
  return (props: any) => {
    const { user } = useContext(AuthContext);
    const location = useLocation();
    const { permissions, permissionsLoaded, hasPermission } = useContext(
      PermissionsContext
    );
    if (!permissionsLoaded) {
      return <PassedComponent {...props} />;
    }

    if (permissionName && !hasPermission(permissionName)) {
      return <PassedComponent {...props} />;
    }

    if (
      !hasPermission('user.acknowledgeCuratorAgreement') &&
      user &&
      !user.acknowledgedGdprAgreementAt
    ) {
      return (
        <ExternalRedirect
          to={`/admin/confidentiality-agreement?referrer_path=${location.pathname}`}
        />
      );
    }

    if (
      hasPermission('user.acknowledgeCuratorAgreement') &&
      user &&
      !user.acknowledgedCuratorAgreementAt
    ) {
      return (
        <ExternalRedirect
          to={`/admin/curator-agreement?referrer_path=${location.pathname}`}
        />
      );
    }

    if (
      hasPermission('user.acknowledgeCuratorAgreement2') &&
      userHasCommercialCuratorGroup(permissions) &&
      user &&
      !user.acknowledgedCuratorAgreement2At &&
      !user.declinedCuratorAgreement2At
    ) {
      return (
        <ExternalRedirect
          to={`/admin/curator-agreement-2?referrer_path=${location.pathname}`}
        />
      );
    }

    return <PassedComponent {...props} />;
  };
};

export default withUserAgreements;
