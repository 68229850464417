import React, { useContext, useRef } from 'react';
import styled, { css } from 'styled-components';

import { useAnalyticsContext } from 'app/shared/utils';
import { AuthContext } from 'app/shared/context/Auth';
import { withSpacingCSS } from 'app/shared/components/styleUtils';

const Container = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 8px;
`;

const Tick = styled.span`
  position: absolute;
  display: none;
  top: -1px;
  left: -1px;
  width: 21px;
  height: 21px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);
`;

const TickStem = styled.span`
  ${({ theme }) => css`
    position: absolute;
    width: 2px;
    height: 13px;
    background-color: ${theme.colors.primary.white.lightest};
    left: 12px;
    top: 3px;
  `}
`;

const TickKick = styled.span`
  ${({ theme }) => css`
    position: absolute;
    width: 6px;
    height: 2px;
    background-color: ${theme.colors.primary.white.lightest};
    left: 7px;
    top: 14px;
  `}
`;

const Square = styled.label<any>`
  ${({ theme }) => css`
    border-radius: 50%;
    position: relative;
    display: block;
    cursor: pointer;
    width: 20px;
    height: 20px;
    transition: all 0.1s ease-out;

    &:hover {
      cursor: pointer;
      box-shadow: none;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &::before {
      content: '';
      border: 1px solid ${theme.colors.primary.black.base};
      border-radius: 10%;
      background-color: transparent;
      display: block;
      box-sizing: border-box;
      width: 20px;
      height: 20px;
      vertical-align: top;
      cursor: pointer;
      transition: all 0.1s ease-out;
    }
  `}
  ${withSpacingCSS};
`;

const Input = styled.input.attrs({ type: 'checkbox' })<{
  hideOnDisable?: boolean;
}>`
  ${({ theme, hideOnDisable = true }) => css`
    position: absolute;
    top: auto;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    width: 1px;
    height: 1px;
    user-select: none;
    white-space: nowrap;

    &:checked + ${Square}:hover {
      box-shadow: none;
    }

    &:checked + ${Square} ${Tick} {
      display: block;
    }

    &:checked + ${Square} ${Tick} ${TickStem} {
      background-color: ${theme.colors.primary.white.lightest};
    }

    &:checked + ${Square} ${Tick} ${TickKick} {
      background-color: ${theme.colors.primary.white.lightest};
    }

    &:checked + ${Square}::before {
      background-color: ${theme.colors.primary.black.base};
      border-color: ${theme.colors.primary.black.base};
    }

    &:disabled + ${Square}:hover {
      box-shadow: none;
      background-color: transparent;
      cursor: not-allowed;
    }

    &:disabled + ${Square} ${Tick} {
      display: ${hideOnDisable ? 'none' : 'block'};
    }

    &:disabled + ${Square}::before {
      border-color: ${theme.colors.accent.primaryAccent.light};
      background-color: transparent;
      cursor: not-allowed;
    }
  `}
`;

interface Props {
  id: string;
  name: string;
  disabled?: boolean;
  onChange?: (e: any) => void;
  'data-qaid'?: string;
  checked?: boolean;
  hideOnDisable?: boolean;
}

export const Checkbox: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  'data-qaid': qaId = '',
  hideOnDisable = true,
  onChange = () => {},
  ...props
}) => {
  const { trackAnalyticsEvent } = useAnalyticsContext();
  const { visitorType } = useContext(AuthContext);
  const checkboxRef = useRef<HTMLInputElement>(null);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const checkboxNode = checkboxRef.current;
    const analyticsEventName = checkboxNode?.checked
      ? 'Checkbox Checked'
      : 'Checkbox Unchecked';

    if (checkboxNode) {
      trackAnalyticsEvent(analyticsEventName, {
        location: checkboxNode.baseURI,
        dataQaid: checkboxNode.dataset.qaid,
        visitor_type: visitorType,
      });
    }

    return onChange(event);
  }

  return (
    <>
      <Container>
        <Input
          type="checkbox"
          {...props}
          onChange={handleChange}
          ref={checkboxRef}
          hideOnDisable={hideOnDisable}
          data-qaid={qaId}
        />
        <Square htmlFor={props.id} data-qaid={`${qaId}-square`}>
          {props.checked && (
            <Tick data-qaid={`${qaId}-tick`}>
              <TickKick />
              <TickStem />
            </Tick>
          )}
        </Square>
        {children}
      </Container>
    </>
  );
};
export default Checkbox;
