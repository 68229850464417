import React from 'react';

import { useCurrentTheme } from 'app/shared/theme';
import { Typography } from 'app/shared/components/atoms/Typography';

interface InputLabelProps {
  label: string | null;
  name?: string;
  hasError?: boolean;
  isRequired?: boolean;
  labelColor?: string;
  mb?: number | number[];
}

export const InputLabel = ({
  label,
  name,
  hasError = false,
  isRequired = false,
  labelColor,
  mb = 2,
}: InputLabelProps) => {
  const { colors } = useCurrentTheme();

  if (!label) {
    return null;
  }

  const color = hasError ? colors.messaging.error.dark : labelColor;

  return (
    <Typography
      lineHeight="100%"
      as="label"
      htmlFor={name}
      fontSize="fontSize50"
      fontWeight="semibold"
      textTransform="uppercase"
      color={color}
      mb={mb}
    >
      {label}
      {isRequired ? '*' : ''}
    </Typography>
  );
};
