export const removeEntriesByKey = (object: object, keysToRemove: string[]) =>
  Object.keys(object)
    .filter(key => !keysToRemove.includes(key))
    .reduce((obj, key) => {
      obj[key] = object[key];
      return obj;
    }, {});

export const keepOnlyEntriesByKey = (object: object, keysToKeep: string[]) =>
  Object.keys(object)
    .filter(key => keysToKeep.includes(key))
    .reduce((obj, key) => {
      obj[key] = object[key];
      return obj;
    }, {});

export const sortEntriesByKey = (object: object) =>
  Object.keys(object)
    .sort()
    .reduce((obj, key) => {
      obj[key] = object[key];
      return obj;
    }, {});

export const objectListFilteredByBooleanConditionOnKeys = (
  objects: any[],
  keysAndBooleanConditions: object
) =>
  objects.filter(
    obj =>
      // @ts-ignore
      !(obj.key in keysAndBooleanConditions) ||
      // @ts-ignore
      keysAndBooleanConditions[obj.key]
  );

export const objectFilteredByBooleanConditionOnKeys = (
  obj: object,
  keysAndBooleanConditions: object
) => {
  let newObj = {};

  for (const key in obj) {
    if (!(key in keysAndBooleanConditions) || keysAndBooleanConditions[key]) {
      newObj[key] = obj[key];
    }
  }

  return newObj;
};

export const fromEntries = (pairs: any[]) => {
  const assign = (object: any, [key, value]: any) =>
    Object.assign(object, { [key]: value });
  return pairs.reduce(assign, {});
};

export const objectForObjectId = (objList: any, objId: number | null) =>
  objList.find((obj: any) => obj && obj.id === objId);

export const objectForObjectKey = (objList: any, objKey: string | null) =>
  objList.find((obj: any) => obj && obj.key === objKey);

export const isEmpty = (obj: any) =>
  [Object, Array].includes((obj || {}).constructor) &&
  !Object.entries(obj || {}).length;
