const HotjarSetup = (hotjarId: any) => {
  // This is a code snippet provided by Hotjar to be pasted on every page we want to
  // track users and collect feedback.
  (function(h: any, o: any, t: any, j: any, a, r) {
    h.hj =
      h.hj ||
      function() {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = { hjid: hotjarId, hjsv: 6 };
    h._scriptPath = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    if (!document.querySelector('script[src*="' + h._scriptPath + '"]')) {
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script');
      (r as any).async = 1;
      (r as any).src = h._scriptPath;
      (a as any).appendChild(r);
    }
  })(window, document, '//static.hotjar.com/c/hotjar-', '.js?sv=');
};

export default HotjarSetup;
