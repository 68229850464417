import { ThemeNameOrFunction } from 'app/shared/hoc/withTheme';

export interface RouteInfo {
  path: string;
  componentPath: string;
  require?: string[];
  postAuthReturnToPath?: string;
  permission?: string;
  permissionContexts?: string[];
  featureFlagsToLoad?: string[];
  themeName: ThemeNameOrFunction;
  localeMessages?: string;
}

const requiresForAdmin = ['auth', 'beamer', 'permissions', 'userAgreements'];

// Please keep in alphabetical order by path, where possible and it makes sense
export const routesConfig: RouteInfo[] = [
  {
    path: '/',
    componentPath: 'app/fan/components/pages/Home',
    require: ['optinMonster'],
    themeName: 'common',
    localeMessages: 'fan',
  },
  {
    path: '/sms-sign-up',
    componentPath: 'app/fan/components/pages/SmsSignUp',
    themeName: 'common',
    localeMessages: 'fan',
  },
  {
    path: '/email-sms-sign-up',
    componentPath: 'app/fan/components/pages/KlaviyoEmailSmsEmbeddedForm',
    themeName: 'common',
    localeMessages: 'fan',
  },
  {
    path: '/about/artists',
    componentPath: 'app/artist/components/pages/ArtistApplyInfo',
    themeName: 'manualCSS',
  },
  {
    path: '/about/artists/apply',
    componentPath: 'app/artist/components/pages/ArtistApplication',
    require: ['auth'],
    themeName: 'manualCSS',
  },
  {
    path: '/about/artists/how-it-works',
    componentPath: 'app/artist/components/pages/ArtistsHowItWorks',
    themeName: 'manualCSS',
  },
  {
    path: '/about/artists/how-it-works-playing',
    componentPath: 'app/artist/components/pages/ArtistsHowItWorksPlaying',
    themeName: 'manualCSS',
  },
  {
    path: '/about/artists/how-it-works-artist-compensation',
    componentPath:
      'app/artist/components/pages/ArtistsHowItWorksArtistCompensation',
    themeName: 'manualCSS',
  },
  {
    path: '/about/artists/how-it-works-video',
    componentPath: 'app/artist/components/pages/ArtistsHowItWorksVideo',
    themeName: 'manualCSS',
  },
  {
    path: '/about/hosts',
    componentPath: 'app/venue/components/pages/VenueApplyInfo',
    themeName: 'manualCSS',
  },
  {
    path: '/about/hosts/apply',
    componentPath: 'app/venue/components/pages/VenueApplication',
    themeName: 'manualCSS',
  },
  {
    path: '/admin/artist-applications',
    componentPath: 'app/admin/components/pages/AdminArtistApplications',
    require: requiresForAdmin,
    permission: 'artistApplication.view',
    permissionContexts: ['artist', 'artistApplication'],
    themeName: 'adminManualCSS',
  },
  {
    path: '/admin/artists',
    componentPath: 'app/admin/components/pages/AdminArtists',
    require: requiresForAdmin,
    permission: 'artist.list.view',
    permissionContexts: ['artist', 'artistOffer', 'user'],
    themeName: 'adminManualCSS',
  },
  {
    path: '/admin/artist-booking-invitations',
    componentPath: 'app/admin/components/pages/AdminOffers',
    require: requiresForAdmin,
    permission: 'artistOffer.list.view',
    permissionContexts: ['artistOffer'],
    themeName: 'adminManualCSS',
  },
  {
    path: '/admin/artist-merge',
    componentPath: 'app/admin/components/pages/AdminArtistMerge',
    require: requiresForAdmin,
    permission: 'artist.merge',
    permissionContexts: ['artist'],
    themeName: 'adminManualCSS',
  },
  {
    path: '/admin/booking-requests',
    componentPath: 'app/admin/components/pages/AdminBookingRequests',
    require: requiresForAdmin,
    permission: 'artist.bookingRequestList.view',
    permissionContexts: ['artist'],
    themeName: 'adminManualCSS',
  },
  {
    path: '/admin/broadcasts',
    componentPath: 'app/admin/components/pages/AdminBroadcasts',
    require: requiresForAdmin,
    permission: 'event.broadcast.access',
    permissionContexts: ['event'],
    themeName: 'adminManualCSS',
  },
  {
    path: '/admin/business-owners',
    componentPath: 'app/admin/components/pages/AdminBusinessOwners',
    require: requiresForAdmin,
    permission: 'businessOwner.list.view',
    permissionContexts: ['businessOwner'],
    themeName: 'adminManualCSS',
  },
  {
    path: '/admin/campaigns',
    componentPath: 'app/admin/components/pages/AdminCampaigns',
    require: requiresForAdmin,
    permission: 'campaign.access',
    permissionContexts: ['campaign', 'user', 'promoCode'],
    themeName: 'adminManualCSS',
  },
  {
    // TODO: Change this to /admin/cities and add permanent redirect
    // /admin/city-directory to /admin/cities
    path: '/admin/city-directory',
    componentPath: 'app/admin/components/pages/AdminCities',
    require: requiresForAdmin,
    permission: 'city.list.view',
    permissionContexts: ['city', 'neighborhood', 'user'],
    featureFlagsToLoad: ['collection_following'],
    themeName: 'adminManualCSS',
  },
  {
    path: '/admin/collections',
    componentPath: 'app/admin/components/pages/AdminCollections',
    require: requiresForAdmin,
    permission: 'eventCollection.access',
    permissionContexts: ['event', 'eventCollection'],
    featureFlagsToLoad: ['collection_following'],
    themeName: 'adminManualCSS',
  },
  {
    path: '/admin/competitions',
    componentPath: 'app/admin/components/pages/AdminCompetitions',
    require: requiresForAdmin,
    permission: 'competition.access',
    permissionContexts: ['competition'],
    themeName: 'adminManualCSS',
  },
  {
    path: '/admin/concert-planner',
    componentPath: 'app/admin/components/pages/AdminEventPlanner',
    require: requiresForAdmin,
    permission: 'event.list.view',
    permissionContexts: [
      'artistOffer',
      'campaign',
      'city',
      'competition',
      'event',
      'eventCollection',
      'partner',
      'visaPresale',
    ],
    themeName: 'adminManualCSS',
  },
  {
    path: '/admin/concert-planner/:showFieldsParam',
    componentPath: 'app/admin/components/pages/AdminEventPlanner',
    require: requiresForAdmin,
    permission: 'event.list.view',
    permissionContexts: ['event'],
    themeName: 'adminManualCSS',
  },
  {
    path: '/admin',
    componentPath: 'app/admin/components/pages/AdminMainPortal',
    require: requiresForAdmin,
    themeName: 'adminManualCSS',
  },
  {
    path: '/admin/countries',
    componentPath: 'app/admin/components/pages/AdminCountries',
    require: requiresForAdmin,
    permission: 'country.access',
    permissionContexts: ['country', 'continent'],
    themeName: 'adminManualCSS',
  },
  {
    path: '/admin/crew-directory',
    componentPath: 'app/admin/components/pages/AdminCrew',
    require: requiresForAdmin,
    permission: 'crew.access',
    permissionContexts: ['crew', 'event', 'role'],
    themeName: 'adminManualCSS',
  },
  {
    path: '/admin/crew-portal/events/:eventId',
    componentPath: 'app/admin/components/pages/AdminCrewEvent',
    require: requiresForAdmin,
    permission: 'event.crew.access',
    permissionContexts: ['artist', 'crew', 'event', 'role', 'venue'],
    themeName: 'adminManualCSS',
  },
  {
    path: '/admin/crew-portal',
    componentPath: 'app/admin/components/pages/AdminCrewPortal',
    require: requiresForAdmin,
    permission: 'event.crew.access',
    permissionContexts: ['artist', 'crew', 'event', 'role', 'venue'],
    themeName: 'adminManualCSS',
  },
  {
    path: '/admin/confidentiality-agreement',
    componentPath: 'app/admin/components/pages/AdminConfidentialityAgreement',
    require: ['auth'],
    themeName: 'adminManualCSS',
  },
  {
    path: '/admin/curator-groups',
    componentPath: 'app/admin/components/pages/AdminCuratorGroups',
    require: requiresForAdmin,
    permission: 'curatorGroup.list.view',
    permissionContexts: ['curatorGroup'],
    themeName: 'adminManualCSS',
  },
  {
    path: '/admin/email-testing',
    componentPath: 'app/admin/components/pages/AdminEmailTest',
    require: requiresForAdmin,
    permission: 'email.emailTest.access',
    permissionContexts: ['email'],
    themeName: 'adminManualCSS',
  },
  {
    path: '/admin/emails',
    componentPath: 'app/admin/components/pages/AdminEmailMessages',
    require: requiresForAdmin,
    permission: 'emailMessage.access',
    permissionContexts: ['emailMessage'],
    themeName: 'adminManualCSS',
  },
  {
    path: '/admin/gdpr-data-removal-form',
    componentPath: 'app/admin/components/pages/AdminGdprDataRemovalForm',
    require: requiresForAdmin,
    permission: 'user.gdprDataRemovalProcess.create',
    permissionContexts: ['user'],
    themeName: 'adminManualCSS',
  },
  {
    path: '/admin/curator-agreement',
    componentPath: 'app/admin/components/pages/AdminCuratorAgreement',
    require: ['auth'],
    themeName: 'adminManualCSS',
  },
  {
    path: '/admin/curator-agreement-2',
    componentPath: 'app/admin/components/pages/AdminCuratorAgreement2',
    require: ['auth'],
    themeName: 'adminManualCSS',
  },
  {
    path: '/admin/custom-email-templates',
    componentPath: 'app/admin/components/pages/AdminCustomEmailTemplates',
    require: requiresForAdmin,
    permission: 'event.list.accessBasic',
    permissionContexts: ['event'],
    themeName: 'adminManualCSS',
  },
  {
    path: '/admin/internal-users',
    componentPath: 'app/admin/components/pages/AdminInternalUsers',
    require: requiresForAdmin,
    permission: 'user.internalList.view',
    permissionContexts: ['user'],
    themeName: 'adminManualCSS',
  },
  {
    path: '/admin/partners',
    componentPath: 'app/admin/components/pages/AdminPartners',
    require: requiresForAdmin,
    permission: 'partner.access',
    permissionContexts: ['partner', 'user'],
    themeName: 'adminManualCSS',
  },
  {
    path: '/admin/print-day-of-show-guestlist',
    componentPath: 'app/admin/components/pages/AdminPrintGuestlistDayOfShow',
    require: requiresForAdmin,
    permission: 'event.guestlist.access',
    permissionContexts: ['event'],
    themeName: 'adminManualCSS',
  },
  {
    path: '/admin/promo-codes',
    componentPath: 'app/admin/components/pages/AdminPromoCodes',
    require: requiresForAdmin,
    permission: 'promoCode.access',
    permissionContexts: ['promoCode'],
    themeName: 'adminManualCSS',
  },
  {
    path: '/admin/roles-and-permissions',
    componentPath: 'app/admin/components/pages/AdminRolesAndPermissions',
    require: requiresForAdmin,
    permission: 'permission.list.view',
    permissionContexts: ['permission'],
    themeName: 'adminManualCSS',
  },
  {
    path: '/admin/site-banner',
    componentPath: 'app/admin/components/pages/AdminSiteBanner',
    require: requiresForAdmin,
    permission: 'setting.global.edit',
    permissionContexts: ['setting'],
    themeName: 'adminManualCSS',
  },
  {
    path: '/admin/themes',
    componentPath: 'app/admin/components/pages/AdminThemes',
    require: requiresForAdmin,
    permission: 'theme.view',
    permissionContexts: ['theme'],
    themeName: 'adminManualCSS',
  },
  {
    path: '/admin/users',
    componentPath: 'app/admin/components/pages/AdminUsers',
    require: requiresForAdmin,
    permission: 'user.list.access',
    permissionContexts: ['user', 'event'],
    themeName: 'adminManualCSS',
  },
  {
    path: '/admin/venue-applications',
    componentPath: 'app/admin/components/pages/AdminVenueApplications',
    require: requiresForAdmin,
    permission: 'venueApplication.view',
    permissionContexts: ['venue', 'venueApplication'],
    themeName: 'adminManualCSS',
  },
  {
    path: '/admin/venues',
    componentPath: 'app/admin/components/pages/AdminVenues',
    require: requiresForAdmin,
    permission: 'venue.list.view',
    permissionContexts: ['venue'],
    themeName: 'adminManualCSS',
  },
  {
    path: '/admin/versions',
    componentPath: 'app/admin/components/pages/AdminVersions',
    require: requiresForAdmin,
    permission: 'tool.versionHistory.access',
    permissionContexts: ['tool'],
    themeName: 'adminManualCSS',
  },
  {
    path: '/admin/versions/:id',
    componentPath: 'app/admin/components/pages/AdminVersion',
    require: requiresForAdmin,
    permission: 'tool.versionHistory.access',
    permissionContexts: ['tool'],
    themeName: 'adminManualCSS',
  },
  {
    path: '/artists/:slug',
    componentPath: 'app/fan/components/pages/ArtistProfile',
    themeName: 'common',
    localeMessages: 'fan',
  },
  {
    path: '/artist/booking-invitation/:id',
    componentPath: 'app/artist/components/pages/ArtistOfferDetails',
    require: ['auth'],
    // We set permissionContexts here to trigger loading of permissions from backend, but we don't
    // require a permission check or set a page-wide permission to check b/c instead of using the
    // built-in page-wide permissions check, we manually check for permissions in the page code
    // since we have more complex logic checking for EITHER has a permission
    // OR is associated with given artist
    permissionContexts: ['artist'],
    themeName: 'adminManualCSS',
  },
  {
    path: '/artist/dashboard',
    componentPath: 'app/artist/components/pages/ArtistDashboardHome',
    require: ['auth'],
    postAuthReturnToPath: '/artist/dashboard',
    themeName: 'adminManualCSS',
  },
  {
    path: '/artist/edit-profile',
    componentPath: 'app/artist/components/pages/ArtistProfileEdit',
    require: ['auth'],
    postAuthReturnToPath: '/artist/edit-profile',
    themeName: 'adminManualCSS',
  },
  {
    path: '/artist/event/:id',
    componentPath: 'app/artist/components/pages/ArtistEventDetails',
    require: ['auth'],
    // We set permissionContexts here to trigger loading of permissions from backend, but we don't
    // require a permission check or set a page-wide permission to check b/c instead of using the
    // built-in page-wide permissions check, we manually check for permissions in the page code
    // since we have more complex logic checking for EITHER has a permission
    // OR is associated with given artist
    permissionContexts: ['artist'],
    themeName: 'adminManualCSS',
  },
  {
    path: '/artist/fans',
    componentPath: 'app/artist/components/pages/ArtistDashboardFans',
    require: ['auth'],
    postAuthReturnToPath: '/artist/fans',
    themeName: 'adminManualCSS',
  },
  {
    path: '/artist/featured-set/:id',
    componentPath: 'app/artist/components/pages/ArtistFeaturedSetDetails',
    require: ['auth'],
    themeName: 'adminManualCSS',
  },
  {
    path: '/artist/network',
    componentPath: 'app/artist/components/pages/ArtistDashboardNetwork',
    require: ['auth'],
    postAuthReturnToPath: '/artist/network',
    themeName: 'adminManualCSS',
  },
  {
    path: '/artist/resources',
    componentPath: 'app/artist/components/pages/ArtistDashboardResources',
    require: ['auth'],
    postAuthReturnToPath: '/artist/resources',
    themeName: 'adminManualCSS',
  },
  {
    path: '/artist/shows',
    componentPath: 'app/artist/components/pages/ArtistDashboardShows',
    require: ['auth'],
    postAuthReturnToPath: '/artist/shows',
    themeName: 'adminManualCSS',
  },
  {
    path: '/auth/secret/new',
    componentPath: 'app/fan/components/pages/PasswordRequest',
    themeName: 'common',
    localeMessages: 'fan',
  },
  {
    path: '/auth/secret/edit',
    componentPath: 'app/fan/components/pages/PasswordReset',
    themeName: 'common',
    localeMessages: 'fan',
  },
  {
    path: '/checkout/:eventId',
    componentPath: 'app/fan/components/pages/Checkout',
    require: ['optinMonster'],
    // We set permissionContexts here to trigger loading of permissions from backend, but we don't
    // require a permission check or set a page-wide permission to check b/c instead of using the
    // built-in page-wide permissions check, we manually check for permissions in the page code
    // since we need permissions only in certain cases, instead of requiring permissions for
    // the entire page
    permissionContexts: ['visaPresale'],
    themeName: 'common',
    localeMessages: 'fan',
  },
  {
    path: '/events/:eventId',
    componentPath: 'app/fan/components/pages/Event',
    // We set permissionContexts here to trigger loading of permissions from backend, but we don't
    // require a permission check or set a page-wide permission to check b/c instead of using the
    // built-in page-wide permissions check, we manually check for permissions in the page code
    // since we need permissions only in certain cases, instead of requiring permissions for
    // the entire page
    permissionContexts: ['city', 'event', 'visaPresale'],
    require: ['optinMonster'],
    themeName: 'common',
    localeMessages: 'fan',
  },
  {
    path: '/cities/:citySlug',
    componentPath: 'app/fan/components/pages/City',
    require: ['hotjar', 'optinMonster'],
    // We set permissionContexts here to trigger loading of permissions from backend, but we don't
    // require a permission check or set a page-wide permission to check b/c instead of using the
    // built-in page-wide permissions check, we manually check for permissions in the page code
    // since we need permissions only in certain cases, instead of requiring permissions for
    // the entire page
    permissionContexts: ['city', 'event', 'visaPresale'],
    themeName: 'common',
    localeMessages: 'fan',
  },
  {
    path: '/cities/:citySlug/lists/:collectionSlug',
    componentPath: 'app/fan/components/pages/Collection',
    require: ['hotjar', 'optinMonster'],
    permissionContexts: ['eventCollection'],
    themeName: 'common',
    localeMessages: 'fan',
  },
  {
    path: '/:citySlug/today',
    componentPath: 'app/fan/components/pages/TodaysEvents',
    themeName: 'common',
    localeMessages: 'fan',
  },
  {
    path: '/community-guidelines',
    componentPath: 'app/fan/components/pages/CommunityGuidelines',
    themeName: 'common',
    localeMessages: 'fan',
  },
  {
    path: '/comp/:slug',
    componentPath: 'app/fan/components/pages/Competition',
    themeName: 'manualCSS',
    localeMessages: 'fan',
  },
  {
    path: '/confirm-venue-details/:uuid',
    componentPath: 'app/venue/components/pages/ConfirmVenueDetails',
    require: ['auth'],
    themeName: 'manualCSS',
  },
  {
    path: '/dashboard',
    componentPath: 'app/fan/components/pages/Dashboard',
    require: ['auth'],
    // We set permissionContexts here to trigger loading of permissions from backend, but we don't
    // require a permission check or set a page-wide permission to check b/c instead of using the
    // built-in page-wide permissions check, we manually check for permissions in the page code
    // since we need permissions only in certain cases, instead of requiring permissions for
    // the entire page
    permissionContexts: ['visaPresale'],
    themeName: 'manualCSS',
    localeMessages: 'fan',
  },
  {
    path: '/featuring/:slug',
    componentPath: 'app/fan/components/pages/FeaturedSet',
    // We set permissionContexts here to trigger loading of permissions from backend, but we don't
    // require a permission check or set a page-wide permission to check b/c instead of using the
    // built-in page-wide permissions check, we manually check for permissions in the page code
    // since we need permissions only in certain cases, instead of requiring permissions for
    // the entire page
    permissionContexts: ['event'],
    themeName: 'common',
  },
  {
    path: '/clp/:variant/cities/:citySlug',
    componentPath: 'app/fan/components/pages/LandingPageWithCity',
    themeName: 'common',
    localeMessages: 'fan',
  },
  {
    path: '/clp/:variant/cities/:citySlug/lists/:collectionSlug',
    componentPath: 'app/fan/components/pages/LandingPageWithCity',
    themeName: 'common',
    localeMessages: 'fan',
  },
  {
    path: '/clp/:variant/lists/:collectionSlug',
    componentPath: 'app/fan/components/pages/LandingPageNoCity',
    themeName: 'common',
    localeMessages: 'fan',
  },
  {
    path: '/clp/:variant/:slug',
    componentPath: 'app/fan/components/pages/LandingPageNoCity',
    themeName: 'common',
    localeMessages: 'fan',
  },
  {
    path: '/host-invitations/:id',
    componentPath: 'app/venue/components/pages/VenueOffer',
    require: ['auth'],
    // We set permissionContexts here to trigger loading of permissions from backend, but we don't
    // require a permission check or set a page-wide permission to check b/c instead of using the
    // built-in page-wide permissions check, we manually check for permissions in the page code
    // since we need permissions only in certain cases, instead of requiring permissions for
    // the entire page
    permissionContexts: ['venue'],
    themeName: 'manualCSS',
  },
  {
    path: '/lists/:collectionSlug',
    componentPath: 'app/fan/components/pages/Collection',
    require: ['hotjar', 'optinMonster'],
    permissionContexts: ['eventCollection'],
    themeName: 'common',
    localeMessages: 'fan',
  },
  {
    path: '/lists/subscribe',
    componentPath: 'app/fan/components/pages/FollowEventCollection',
    themeName: 'common',
    localeMessages: 'fan',
    featureFlagsToLoad: ['collection_following'],
  },
  {
    path: '/lists/unsubscribe',
    componentPath: 'app/fan/components/pages/UnfollowEventCollection',
    themeName: 'common',
    localeMessages: 'fan',
    featureFlagsToLoad: ['collection_following'],
  },
  {
    path: '/profile/edit',
    componentPath: 'app/fan/components/pages/Profile',
    require: ['auth'],
    localeMessages: 'fan',
    themeName: 'common',
  },
  {
    path: '/about/artists/request-artist',
    componentPath: 'app/artist/components/pages/ArtistAccessRequest',
    themeName: 'manualCSS',
  },
  {
    path: '/set-up-artist-access',
    componentPath: 'app/artist/components/pages/ArtistAccessSetUp',
    require: ['auth'],
    themeName: 'manualCSS',
  },
  {
    path: '/claim_account',
    componentPath: 'app/fan/components/pages/ClaimGuestUserAccount',
    themeName: 'common',
    localeMessages: 'fan',
  },
  {
    path: '/signin',
    componentPath: 'app/fan/components/pages/SignIn',
    themeName: 'common',
    localeMessages: 'fan',
  },
  {
    path: '/signup',
    componentPath: 'app/fan/components/pages/SignIn',
    themeName: 'common',
    localeMessages: 'fan',
  },
  {
    path: '/v/artistresources',
    componentPath: 'app/artist/components/pages/ArtistResources',
    themeName: 'manualCSS',
  },
  {
    path: '/venue-community-host-assurance-promise',
    componentPath:
      'app/venue/components/pages/VenueCommunityHostAssurancePromise',
    require: ['auth'],
    postAuthReturnToPath: '/venue-terms-and-conditions',
    themeName: 'manualCSS',
  },
  {
    path: '/venue-terms-and-conditions',
    componentPath: 'app/venue/components/pages/VenueTermsAndConditions',
    require: ['auth'],
    postAuthReturnToPath: '/venue-terms-and-conditions',
    themeName: 'manualCSS',
  },
  {
    path: '/videos/:slug',
    componentPath: 'app/fan/components/pages/Video',
    localeMessages: 'fan',
    themeName: 'common',
  },
  {
    path: '/videos',
    componentPath: 'app/fan/components/pages/Videos',
    localeMessages: 'fan',
    themeName: 'common',
  },
  {
    path: '/visaoffer',
    componentPath: 'app/fan/components/pages/VisaOffer',
    themeName: 'common',
    localeMessages: 'fan',
  },
  {
    path: '/visaoffer/response',
    componentPath: 'app/fan/components/pages/VisaOfferRedirect',
    themeName: 'common',
    localeMessages: 'fan',
  },
  {
    path: '/sofar-sitemap',
    componentPath: 'app/fan/components/pages/SiteMap',
    themeName: 'common',
    localeMessages: 'fan',
  },
];

export const defaultRouteConfig: RouteInfo = {
  path: '*',
  componentPath: 'app/shared/components/pages/Prismic',
  themeName: 'manualCSS',
  localeMessages: 'index',
};
