import React from 'react';
import { useIntl } from 'react-intl';

import { useAnalyticsContext } from 'app/shared/utils';
import { useAuthFormContext } from 'app/shared/context';
import {
  ActionLink,
  NavigationLink,
  ZendeskLink,
} from 'app/shared/components/atoms';
import { FormErrorMessage } from 'app/shared/components/molecules';

interface Props {
  onClickSignUp?: () => void;
  onClickSignIn?: () => void;
  genericIntlId: string;
  displayIcon?: boolean;
}

export const AuthApiError: React.FC<React.PropsWithChildren<Props>> = ({
  onClickSignIn,
  onClickSignUp,
  genericIntlId,
  displayIcon = true,
}) => {
  const { formatMessage } = useIntl();
  const {
    apiError,
    isCompleteSignUpFlow,
    validationErrors,
    thirdPartyAuthProvider,
  } = useAuthFormContext();
  const { trackAnalyticsEvent } = useAnalyticsContext();

  const errorMessage = validationErrors.general || apiError?.message;

  if (!errorMessage) {
    return null;
  }

  const ifTheProblemPersistsContactUs = formatMessage(
    { id: 'authentication.error.ifTheProblemPersistsContactUs' },
    {
      a: (chunks) => <ZendeskLink isErrorMessage={true}>{chunks}</ZendeskLink>,
    }
  );

  if (
    !!thirdPartyAuthProvider &&
    errorMessage.includes('Email was not shared')
  ) {
    return (
      <FormErrorMessage
        displayIcon={displayIcon}
        data-qaid="email-missing-error"
      >
        {formatMessage(
          { id: 'authentication.error.thirdPartyAuthEmailMissingError' },
          { provider: thirdPartyAuthProvider }
        )}{' '}
        {ifTheProblemPersistsContactUs}
      </FormErrorMessage>
    );
  } else if (thirdPartyAuthProvider) {
    return (
      <FormErrorMessage displayIcon={displayIcon}>
        {formatMessage({
          id: 'authentication.error.genericThirdPartyAuthError',
        })}{' '}
        {ifTheProblemPersistsContactUs}
      </FormErrorMessage>
    );
  }

  if (errorMessage.includes('User already exists') && onClickSignIn) {
    trackAnalyticsEvent('Sign Up Form Error User Already Exists', {
      is_complete_sign_up_flow: isCompleteSignUpFlow,
    });
    return (
      <FormErrorMessage displayIcon={displayIcon}>
        {formatMessage(
          { id: 'authentication.error.userAlreadyExists' },
          {
            a: (chunks) => (
              <ActionLink isErrorMessage={true} onClick={onClickSignIn}>
                {chunks}
              </ActionLink>
            ),
          }
        )}
      </FormErrorMessage>
    );
  }

  if (errorMessage.includes('No such user') && onClickSignUp) {
    return (
      <FormErrorMessage displayIcon={displayIcon}>
        {formatMessage(
          { id: 'authentication.error.noSuchUser' },
          {
            a: (chunks) => (
              <ActionLink isErrorMessage={true} onClick={onClickSignUp}>
                {chunks}
              </ActionLink>
            ),
          }
        )}
      </FormErrorMessage>
    );
  }

  if (errorMessage.includes('Password incorrect')) {
    return (
      <FormErrorMessage displayIcon={displayIcon}>
        {formatMessage(
          { id: 'authentication.error.wrongPassword' },
          {
            a: (chunks) => (
              <NavigationLink isErrorMessage={true} href="/auth/secret/new">
                {chunks}
              </NavigationLink>
            ),
          }
        )}
      </FormErrorMessage>
    );
  }

  return (
    <FormErrorMessage displayIcon={displayIcon}>
      {formatMessage({ id: genericIntlId })}
      {ifTheProblemPersistsContactUs}
    </FormErrorMessage>
  );
};

export default AuthApiError;
