import { getPermissionsContextsForAdminNavbar } from 'app/shared/utils/navbar';
import { useRouteInfoForCurrentPath } from 'app/shared/utils/useRouteInfoForCurrentPath';

export const usePermissionContextsForCurrentPath = () => {
  const routeInfo = useRouteInfoForCurrentPath();

  let permissionContexts = routeInfo?.permissionContexts || [];

  if (routeInfo?.require?.includes('userAgreements')) {
    permissionContexts.push('user');
  }

  if (routeInfo?.require?.includes('permissions')) {
    permissionContexts = getPermissionsContextsForAdminNavbar(
      permissionContexts
    );
  }

  return permissionContexts;
};
