import React, { useContext, useRef } from 'react';
import styled, { css } from 'styled-components';

import { useAnalyticsContext } from 'app/shared/utils';
import { makeOpaque } from 'app/shared/utils/colors';
import { AuthContext } from 'app/shared/context/Auth';

const Center = styled.label`
  display: flex;
  align-items: center;
`;

export const Circle = styled.div<{ hasError?: boolean }>`
  ${({ theme, hasError }) => css`
    border-radius: 100%;
    display: block;
    width: 20px;
    height: 20px;
    transition: all 0.1s ease-out;

    &:hover {
      box-shadow: 0px 0px 0px 8px
          ${makeOpaque(theme.colors.primary.black.base, 0.1)},
        inset 0 0 0 10px ${makeOpaque(theme.colors.primary.black.base, 0.1)};
    }

    &::before {
      content: '';
      border: 1px solid ${hasError ? theme.colors.messaging.error.dark : theme.colors.primary.black.base};
      border-radius: 100%;
      background-color: transparent;
      display: block;
      box-sizing: border-box;
      width: 20px;
      height: 20px;
      vertical-align: top;
      cursor: pointer;
      transition: all 0.1s ease-out;
    }
  `}
`;

export const Input = styled.input`
  ${({ theme }) => css`
    position: absolute;
    top: auto;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    width: 1px;
    height: 1px;
    white-space: nowrap;

    &:checked + ${Circle}:hover {
      box-shadow: 0px 0px 0px 8px
        ${makeOpaque(theme.colors.accent.primaryAccent.base, 0.1)};
    }

    &:checked + ${Circle}::before {
      background-color: ${theme.colors.primary.black.base};
      border-color: ${theme.colors.primary.black.base};
    }

    &:disabled + ${Circle}:hover {
      box-shadow: none;
    }

    &:disabled + ${Circle}::before {
      border-color: ${theme.colors.primary.black.base};
      background-color: transparent;
      cursor: not-allowed;
    }
  `}
`;

export const Wrapper = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 100%;
  display: inline-block;
`;

export const Label = styled.div<{ disabled?: boolean }>`
  ${({ theme, disabled }) => css`
    margin-left: 10px;
    color: ${theme.colors.primary.black.base};
    line-height: 100%;

    ${
      disabled &&
      css`
        color: ${theme.colors.primary.black.lighter};
      `
    }
  `}
`;

interface Props {
  'data-qaid'?: string;
  disabled?: boolean;
  onChange?: (e: any) => void;
  checked?: boolean;
  value?: any;
  id: string;
  name: string;
  hasError?: boolean;
}

export const Radio: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  'data-qaid': dataQaid = '',
  onChange = () => {},
  checked,
  hasError,
  ...props
}) => {
  const { trackAnalyticsEvent } = useAnalyticsContext();
  const { visitorType } = useContext(AuthContext);
  const radioRef = useRef<HTMLInputElement>(null);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const radioNode = radioRef.current;

    if (radioNode) {
      trackAnalyticsEvent('Radio Button Clicked', {
        location: radioNode.baseURI,
        dataQaid,
        visitor_type: visitorType,
      });
    }

    return onChange(event);
  }

  return (
    <Center htmlFor={props.id} data-qaid={dataQaid}>
      <Wrapper>
        <Input
          type="radio"
          {...props}
          onChange={handleChange}
          checked={checked}
          ref={radioRef}
        />
        <Circle hasError={hasError} />
      </Wrapper>
      {children && (
        <Label disabled={props.disabled} data-qaid={`${dataQaid}-label`}>
          {children}
        </Label>
      )}
    </Center>
  );
};
