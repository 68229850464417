import { get } from 'app/shared/utils/get';
import {
  keepOnlyEntriesByKey,
  removeEntriesByKey,
} from 'app/shared/utils/object';

// See also corresponding list in SharedGetDataParameters typing
// Feel free to add to this list as needed - EVERYTHING YOU ADD SHOULD ALSO BE ADDED TO SharedGetDataParameters typing
const queryMetaParamNames = [
  'fetchPolicy',
  'notifyOnNetworkStatusChange',
  'onCompleted',
  'refetch',
  'skip',
  'ssr',
];

const queryVariables = (params: object) =>
  removeEntriesByKey(params, queryMetaParamNames);

const metaParams = (params: object) =>
  keepOnlyEntriesByKey(params, queryMetaParamNames);

export const queryParams = (params: object) => {
  let vParams = {
    variables: queryVariables(params),
  };
  let mParams = metaParams(params);
  return {
    ...vParams,
    ...mParams,
  };
};

export const dataGetter = (data: any, rootDataKey: string) => (
  dataKey: string,
  defaultValue?: any
) => get(data, `${rootDataKey}.${dataKey}`, defaultValue);
