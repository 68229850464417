import React from 'react';
import styled, { css } from 'styled-components';

import {
  withFlexCSS,
  WithFlexProps,
  withFontCSS,
  WithFontProps,
  withSpacingCSS,
  WithSpacingProps,
} from 'app/shared/components/styleUtils';

export interface ListItemProps
  extends React.ComponentPropsWithRef<'li'>,
    WithSpacingProps,
    WithFlexProps,
    WithFontProps {
  isDisabled?: boolean;
  isSelected?: boolean;
  invertColors?: boolean;
  isLastChild?: boolean;
}

export const ListItem = styled.li<ListItemProps>`
  ${({ theme, isDisabled, isSelected, invertColors, isLastChild }) => css`
    list-style: none;
    cursor: pointer;
    color: ${invertColors
      ? theme.colors.primary.white.base
      : theme.colors.primary.black.base};

    ${!isLastChild &&
      `border-bottom: 1px solid
      ${
        invertColors
          ? theme.colors.primary.black.light
          : theme.colors.accent.primaryAccent.base
      };`}

    &:hover,
    &:active,
    &:focus {
      background-color: ${invertColors
        ? theme.colors.primary.black.light
        : theme.colors.primary.white.base};
    }

    ${isSelected &&
      css`
        background-color: ${invertColors
          ? theme.colors.primary.black.light
          : theme.colors.primary.white.base};
      `}

    ${isDisabled &&
      css`
        cursor: initial;
        color: ${theme.colors.primary.black.lightest};

        &:hover,
        &:active,
        &:focus {
          background-color: transparent;
        }
      `}
  `}

  ${withSpacingCSS};
  ${withFontCSS};
  ${withFlexCSS};
`;
